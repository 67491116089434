//--- All Mixins - Related Stuff Included In This Area ---↓

// Transition
@mixin transition {
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
@mixin transition-2 {
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
}

// Absolute center
@mixin absCenter {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

// Header Middle Dropdown Menu
@mixin dropdown {
	.hm-dropdown {
		position: absolute;
		background: #ffffff;
		width: 230px;
		padding: 20px 0 24px;
		transform-origin: 0 0 0;
		transform: scaleY(0);
		box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.2);
		@include transition;
		z-index: 9;
		> li {
			&.active {
				> a {
					color: $hover-color;
				}
			}
			> a {
				padding: 10px 20px;
				display: block;
			}
		}
	}
	&:hover {
		.hm-dropdown {
			transform: scaleY(1);
		}
	}
}

// Megamenu
@mixin megamenu {
	.hm-megamenu {
		background: #ffffff;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		padding: 30px;
		@include transition;
		transform-origin: 0 0 0;
		transform: scaleY(0);
		position: absolute;
		width: calc(100% - 30px);
		left: 15px;
		right: auto;
		top: 100%;
		-webkit-box-shadow: 0 0 5px rgba(0, 0, 0, .1);
		box-shadow: 0 0 5px rgba(0, 0, 0, .1);
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		z-index: 99;
	}
	&:hover {
		.hm-megamenu {
			transform: scaleY(1);
		}
	}
}

// JB's New Price
@mixin new-price {
	.new-price {
		font-size: 20px;
		font-weight: 700;
		color: $bg-alizarin_color;
	}
}

// JB's Old Price
@mixin old-price {
	.old-price {
		font-size: 16px;
		color: $dim-gray_color;
		font-weight: 400;
		text-decoration: line-through;
		margin-right: 5px;
	}
}

// JB's Slider Dots
@mixin jb-slick_dots {
	.slick-dots {
		position: absolute;
		bottom: 20px;
		left: 0;
		right: 0;
		text-align: center;
		padding: 0 30px;
		z-index: 8;
		li {
			display: inline-block;
			margin: 0 3px;
			cursor: pointer;
			font-size: 0;
			width: 15px;
			height: 15px;
			background: transparent;
			border: 2px solid $bg-white_color;
			-webkit-border-radius: 100%;
			-moz-border-radius: 100%;
			-ms-border-radius: 100%;
			-o-border-radius: 100%;
			border-radius: 100%;
			@include transition;
			button {
				border: none;
			}
			&.slick-active {
				background-color: $bg-white_color;
			}
			&:hover {
				background-color: $bg-white_color;
			}
		}
	}
}

/* ---JB's Quantity--- */
@mixin jb-quantity {
	.quantity {
		.cart-plus-minus {
			margin: 0 auto;
			position: relative;
			width: 76px;
			text-align: left;
			> .cart-plus-minus-box {
				border: 1px solid $border-color;
				height: 46px;
				text-align: center;
				width: 48px;
				width: 48px;
				width: 3rem;
				background: $bg-white_color;
			}
			> .qtybutton {
				border-bottom: 1px solid $border-color;
				border-right: 1px solid $border-color;
				border-top: 1px solid $border-color;
				cursor: pointer;
				height: 23px;
				line-height: 20px;
				position: absolute;
				text-align: center;
				@include transition;
				width: 28px;
				line-height: 26px;
				&:hover {
					background: $bg-whisper_color;
				}
				&.dec {
					bottom: 0;
					right: 0;
				}
				&.inc {
					border-bottom: none;
					top: 0;
					right: 0;
				}
			}
		}
	}
}

// Breakpoints
@mixin breakpoint($point) {
	// Fixed Devices
	@if $point == xx-large {
		@media (min-width: 1920px) {
			@content;
		}
	}
	@if $point == x-large {
		@media only screen and (min-width: 1501px) and (max-width: 1919px) {
			@content;
		}
	}
	// Only For The Laptop Device
	@if $point == laptop {
		@media only screen and (min-width: 1366px) and (max-width: 1500px) {
			@content;
		}
	}
	@if $point == large {
		@media only screen and (min-width: 1200px) and (max-width: 1500px) {
			@content;
		}
	}
	@if $point == normal {
		@media only screen and (min-width: 992px) and (max-width: 1199px) {
			@content;
		}
	}
	@if $point == medium {
		@media only screen and (min-width: 768px) and (max-width: 991px) {
			@content;
		}
	}
	@if $point == small {
		@media only screen and (min-width: 576px) and (max-width: 767px) {
			@content;
		}
	}
	@if $point == x-small {
		@media only screen and (min-width: 480px) and (max-width: 575px) {
			@content;
		}
	}
	@if $point == xx-small {
		@media (max-width: 479px) {
			@content;
		}
	}
	//  Multiple Devices
	@if $point == max-x_large {
		@media (max-width: 1501px) {
			@content;
		}
	}
	@if $point == max-large {
		@media (max-width: 1500px) {
			@content;
		}
	}
	@if $point == max-normal {
		@media (max-width: 1199px) {
			@content;
		}
	}
	@if $point == max-medium {
		@media (max-width: 991px) {
			@content;
		}
	}
	@if $point == max-small {
		@media (max-width: 767px) {
			@content;
		}
	}
	@if $point == max-x_small {
		@media (max-width: 575px) {
			@content;
		}
	}
}
