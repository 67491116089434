/*----------------------------------------*/
/*  11 - Components - Slider
/*----------------------------------------*/
// Home One
.jb-slider_area {
	.main-slider {
		.slider-content {
			max-width: 52%;
			text-align: center;
			position: absolute;
			top: 50%;
			left: auto;
			transform: translateY(-50%);
			z-index: 8;
			@include breakpoint (max-normal) {
				max-width: 100%;
				width: 100%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
			> span {
				font-size: 16px;
				color: #ffffff;
				font-weight: 700;
				text-transform: uppercase;
				margin-top: 0;
				@include breakpoint (xx-small) {
					font-size: 14px;
				}
			}
			> h2 {
				font-size: 90px;
				color: #ffffff;
				font-weight: 700;
				text-transform: uppercase;
				margin: 0;
				padding-top: 20px;
				@include breakpoint (max-small) {
					font-size: 40px;
					margin-bottom: 0;
					padding-bottom: 15px;
				}
			}
			> h3 {
				font-size: 50px;
				text-transform: uppercase;
				font-weight: 700;
				color: #ffffff;
				margin: 0;
				padding-bottom: 15px;
				@include breakpoint (max-small) {
					font-size: 35px;
				}
				@include breakpoint (xx-small) {
					font-size: 20px;
				}
			}
			> h5 {
				font-size: 25px;
				text-transform: capitalize;
				font-weight: 400;
				color: #ffffff;
				margin: 0;
				padding-bottom: 20px;
				@include breakpoint (max-small) {
					font-size: 30px;
				}
				@include breakpoint (xx-small) {
					font-size: 18px;
				}
			}
		}
	}
}
// Home Two
.jb-slider_area-2 {
	.main-slider {
		.slider-content {
			> h2 {
				@include breakpoint (normal) {
					font-size: 70px;
				}
			}
			> h3 {
				@include breakpoint (normal) {
					font-size: 40px;
				}
			}
		}
	}
}

// Home Three
.slider-with_banner {
	.jb-slider_area-3 {
		.main-slider {
			.slider-content {
				max-width: 100%;
				top: 26%;
				left: auto;
				width: 100%;
				@include breakpoint (medium) {
					left: auto;
					right: 10%;
				}
				@include breakpoint (max-normal) {
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
				}
				> h3 {
					font-size: 35px;
					color: $body-text_color;
					font-weight: $body-font_weight;
					letter-spacing: 5px;
					@include breakpoint (normal) {
						font-size: 30px;
					}
					@include breakpoint (xx-small) {
						font-size: 25px;
					}
				}
				> h2 {
					font-size: 65px;
					color: $hover-color;
					padding-top: 0;
					padding-bottom: 5px;
					@include breakpoint (normal) {
						font-size: 45px;
					}
					@include breakpoint (xx-small) {
						font-size: 35px;
					}
				}
				> h5 {
					color: $body-text_color;
					font-weight: $body-font_weight;
					letter-spacing: 5px;
				}
				> .jb-btn-ps_center {
					border: 1px solid $hover-color;
					display: inline-block;
					> .jb-btn {
						background: transparent;
						width: 150px;
						height: 45px;
						line-height: 45px;
						color: $hover-color;
						border-radius: 0;
						&:hover {
							background: $hover-color;
							color: $bg-white_color;
						}
					}
				}
			}
			.slider-content-2 {
				@include absCenter;
				width: 100%;
				> h3 {
					color: $bg-white_color;
				}
				> h2 {
					color: $bg-yellow_color;
				}
				> h5 {
					color: $bg-white_color;
				}
				> .jb-btn-ps_center {
					border: 1px solid $bg-white_color;
					display: inline-block;
					> .jb-btn {
						background: transparent;
						width: 151px;
						height: 45px;
						line-height: 45px;
						color: $bg-white_color;
						border-radius: 0;
						@include transition;
						&:hover {
							background: $hover-color;
							color: $hover-color;
						}
					}
					&:hover {
						border-color: $hover-color;
					}
				}
			}
		}
	}
}

/* --Slider Background Image-- */
// Home One
.bg-1,
.bg-2 {
	background-image: url('../image/slider/1.jpg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	min-height: 745px;
	@include breakpoint (max-large) {
		min-height: 475px;
	}
	@include breakpoint (xx-small) {
		min-height: 430px;
		background-position: center left;
	}
}
.bg-2 {
	background-image: url('../image/slider/2.jpg');
}
// Home Two
.bg-3,
.bg-4 {
	background-image: url('../image/slider/3.jpg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	min-height: 556px;
	width: 100%;
	@include breakpoint (large) {
		min-height: 395px;
	}
	@include breakpoint (xx-small) {
		min-height: 430px;
		background-position: center left;
	}
}
.bg-4 {
	background-image: url('../image/slider/4.jpg');
}

// Home Three
.bg-5,
.bg-6 {
	background-image: url('../image/slider/5.jpg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	min-height: 955px;
	@include breakpoint (large) {
		min-height: 670px;
	}
	@include breakpoint (normal) {
		min-height: 600px;
	}
	@include breakpoint (medium) {
		min-height: 580px;
	}
	@include breakpoint (max-small) {
		min-height: 480px;
	}
}
.bg-6 {
	background-image: url('../image/slider/6.jpg');
}
/* ---Slick Slider Full Height Customization--- */
// Banner item related stuff
.banner-full {
	background-image: url('../image/banner/1-6.jpg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	min-height: 955px;
	@include breakpoint (large) {
		min-height: 670px;
	}
	@include breakpoint (normal) {
		min-height: 600px;
	}
	@include breakpoint (small) {
		background-position: unset;
	}
	@include breakpoint (xx-small) {
		min-height: 595px;
	}
}
.banner-half-1 {
	background-image: url('../image/banner/1-7.jpg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	min-height: 477.5px;
	@include breakpoint (large) {
		min-height: 335px;
	}
	@include breakpoint (normal) {
		min-height: 300px;
	}
	@include breakpoint (max-small) {
		background-position: center left;
		min-height: 630px;
	}
	@include breakpoint (xx-small) {
		background-position: unset;
		min-height: 298px;
	}
}
.banner-half-2 {
	background-image: url('../image/banner/1-8.jpg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	min-height: 477.5px;
	@include breakpoint (large) {
		min-height: 335px;
	}
	@include breakpoint (normal) {
		min-height: 300px;
	}
	@include breakpoint (max-small) {
		background-position: center right;
		min-height: 630px;
	}
	@include breakpoint (xx-small) {
		background-position: unset;
		min-height: 298px;
	}
}

// Home Four
.bg-7,
.bg-8 {
	background-image: url('../image/slider/7.jpg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	min-height: 535px;
	@include breakpoint (normal) {
		min-height: 503px;
	}
	@include breakpoint (xx-small) {
		min-height: 380px;
	}
}
.bg-8 {
	background-image: url('../image/slider/8.jpg');
}
/* ---JB's Slider Progress Bar--- */
.slider-progress {
	-webkit-animation: initial;
	animation: initial;
	background: rgba(0, 0, 0, .3);
	height: 5px;
	left: 0;
	opacity: 0.8;
	position: absolute;
	top: 0;
	z-index: 4;
}

.slick-current .slider-progress {
	-webkit-animation: timebar 8s ease-in-out 0s 1 backwards;
	animation: timebar 8s ease-in-out 0s 1 backwards;
}

// JB's Keyframes
@-webkit-keyframes timebar {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}
@keyframes timebar {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}

/* Slider Navigation */
.main-slider {
	.slick-arrow {
		display: block;
		background: url('../image/slider/slide-icon/1.png') no-repeat;
		width: 20px;
		height: 29px;
		color: $bg-cinnabar_color;
		text-align: center;
		font-size: 0;
		position: absolute;
		z-index: 8;
		cursor: pointer;
		border: none;
		top: 50%;
		-moz-transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
		opacity: 0;
		visibility: hidden;
		left: 20px;
		@include breakpoint (xx-small) {
			display: none !important;
		}
		&:hover {
			background-position: 0 100%;
		}
	}
	.slick-next {
		background: url('../image/slider/slide-icon/2.png') no-repeat;
		left: auto;
		right: 20px;
	}
	&:hover {
		.slick-arrow {
			opacity: 1;
			visibility: visible;
		}
	}
}

/* ---Main Slider Dots--- */
.main-slider {
	@include jb-slick_dots;
}

/* ---Slider With Various Styles--- */
.slider-with-various_styles {
	background-image: url("../image/banner/bg-slide.png");
	background-color: $bg-whisper_color;
	background-repeat: no-repeat;
	background-position: left;
	padding: 30px;
	@include breakpoint (xx-large) {
		background-position: center;
	}
	@include breakpoint (x-large) {
		background-position: center;
	}
	@include breakpoint (max-normal) {
		padding: 30px 0;
	}
	.jb-slider_area-4 {
		.main-slider {
			.slider-content {
				top: 50%;
				left: 10%;
				transform: translateY(-50%);
				width: auto;
				@include breakpoint (xx-small) {
					max-width: 95%;
					left: 15px !important;
				}
				> h2 {
					font-size: 50px;
					padding-bottom: 15px;
					@include breakpoint (xx-small) {
						font-size: 35px;
					}
				}
				> h3 {
					font-size: 40px;
					@include breakpoint (xx-small) {
						font-size: 30px;
					}
				}
				.jb-btn-ps_center {
					.jb-btn {
						background: $bg-white_color;
						color: $body-text_color;
						border: 0;
						&:hover {
							background: $hover-color;
							border-color: $hover-color;
						}
					}
				}
			}
			.slider-content-2 {
				top: 50%;
				left: auto;
				right: 10%;
				transform: translateY(-50%);
				> h3 {
					font-size: 30px;
					text-transform: capitalize;
					padding-bottom: 0;
				}
				> h2 {
					font-size: 35px;
					padding-top: 15px;
					padding-bottom: 15px;
					color: $bg-yellow_color;
				}
				> h5 {
					padding-bottom: 25px;
				}
				.jb-btn-ps_center {
					.jb-btn {
						background: transparent;
						color: $bg-white_color;
						border: 1px solid $bg-white_color;
						&:hover {
							background: $hover-color;
							border-color: $hover-color;
						}
					}
				}
			}
		}
	}
	.jb-special-product_area-2 {
		background: $bg-white_color;
		border: 2px solid $hover-color;
		@include breakpoint (max-medium) {
			margin-bottom: 30px;
		}
		@include breakpoint (max-x_small) {
			margin-top: 30px;
		}
		.jb-section_area {
			> .section_title {
				font-size: 18px;
				width: 100%;
				line-height: 50px;
				&:before {
					display: none;
				}
				span {
					&:before {
						line-height: 50px;
						font-size: 18px;
					}
				}
			}
		}
		.jb-special-product_slider-2 {
			padding-bottom: 60px;
			.jb-slide-item {
				text-align: center;
				.jb-single_product {
					.product-img {
						img {
							width: 100%;
						}
					}
					.jb-product_content {
						.manufacturer {
							padding-top: 20px;
							> a {
								font-weight: 300;
								text-transform: uppercase;
							}
						}
						.product-name {
							> h6 {
								margin-bottom: 0;
								> a {
									padding-top: 15px;
									font-weight: 600;
									display: block;
								}
							}
						}
						.rating-box {
							padding-top: 15px;
							> ul {
								> li {
									display: inline-block;
									> i {
										font-size: 11px;
									}
								}
							}
						}
						.price-box {
							@include old-price;
							@include new-price;
							padding-top: 15px;
						}
						.add-actions {
							padding: 15px 0 0;
							> ul {
								> li {
									.jb-add_cart {
										width: 140px;
										height: 40px;
										line-height: 40px;
										display: block;
										border-radius: $jb-border_radius;
										border: 1px solid $border-color;
										text-transform: uppercase;
										text-align: center;
										margin: 0 auto;
										&:hover {
											border-color: $hover-color;
											background-color: $hover-color;
											color: $bg-white_color !important;
										}
									}
								}
							}
						}
					}
				}
			}
			.slick-dots {
				top: auto;
				right: 50%;
				left: auto;
				-webkit-transform: none;
				transform: none;
				padding: 0;
				bottom: 25px;
				transform: translateX(50%);
			}
		}
	}
	.banner-two_columns {
		padding-top: 30px;
	}
}
