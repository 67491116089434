//--- All Variables - Related Stuff Included In This Area ---↓

//--- Typography Section Variables ---↓

// Color
$body-text_color: #333333;
$heading-color: #333333;
$border-color: #e5e5e5;
$dim-gray_color: #666666;
$hover-color: #0084c5;
$bg-white_color: #ffffff;
$bg-alizarin_color: #ea3a3c;
$white-smoke_color: #f4f4f4;
$input-placeholder_color: #888888;
$bg-whisper_color: #ebebeb;
$bg-silver_color: #bbbbbb;
$bg-eclipse_color: #393939;
$bg-nero_color: #222222;
$bg-yellow_color: #fff000;
$bg-cinnabar_color: #d64444;
$bg-wedgewood_color: #516a7e;
$bg-cello_color: #344b5d;
$bg-red_color: #ff0000;

// Font Size
$body-font_size: 14px;
$heading-font_size: 24px;
$jb-border_radius: 4px;

// Font Line Height
$line-height: 1.4;
$heading-line_height: 1;

// Font Weight
$body-font_weight: 400;
$heading-font_weight: 700;

// Font Family
$body-font: 'Khula', sans-serif;
$heading-font: 'Khula', sans-serif;

//----Social Link Color Variables ----↓
$facebook-color: #3b579d;
$twitter-color: #3acaff;
$google-plus-color: #dd4b39;
$linkedin-color: #0097bd;
$pinterest-color: #cb2027;
$instagram-color: #833ab4;
$skype-color: #00aff0;
$share-color: #fe6d4c;
$youtube-color: #d72525;
