/*----------------------------------------*/
/*  25 - Section - Header
/*----------------------------------------*/
/* ---Begin Header Top--- */
.header-top_area {
	border-bottom: 1px solid #dbdbdb;
	padding: 10px 0;
	@include breakpoint (max-small) {
		display: none;
	}
}

/* ---Header Top Information Area--- */
.header-info_area {
	span {
		color: $dim-gray_color;
	}
}

/* ---Header Top Menu--- */
.ht-menu {
	> ul {
		display: flex;
		justify-content: flex-end;
		> li {
			display: inline-block;
			position: relative;
			padding-left: 15px;
			&:hover {
				.ht-dropdown {
					transform: scaleY(1);
				}
			}
			// Begin Header Top Dropdown Area
			.ht-dropdown {
				-webkit-transform: scaleY(0);
				transform: scaleY(0);
				-webkit-transform-origin: 0 0 0;
				transform-origin: 0 0 0;
				transition: all 0.3s ease-in-out;
				position: absolute;
				top: 30px;
				left: auto;
				background: #fff;
				border: 1px solid #d9d9d9;
				width: 120px;
				padding: 10px;
				right: 0;
				z-index: 99;
				> li {
					&.active {
						background: #0084c5;
						a {
							color: #ffffff;
							&:hover {
								color: #ffffff !important;
							}
						}
					}
					&:hover {
						background: #0084c5;
						a {
							color: #ffffff !important;
						}
					}
					a {
						display: block;
						padding: 0 10px;
						line-height: 25px;
						margin-top: 5px;
						img {
							margin-right: 5px;
						}
					}
				}
			}
			.ht-currency {
				left: 0;
				width: 80px;
			}
			// Header Top Dropdown Area End Here
			> a {
				color: $dim-gray_color;
				img {
					margin-right: 5px;
				}
				> i {
					margin-right: 7px;
					font-size: 11px;
				}
			}
		}
	}
}
/* ---Header Middle Area--- */
.header-middle_area {
	@include breakpoint (max-x_small) {
		padding-top: 30px;
	}
	> .container {
		position: relative;
		padding: 0 15px;
		@include breakpoint (max-x_small) {
			padding: 0 15px;
		}
	}
	@include breakpoint (small) {
		padding-top: 30px;
	}
}

/* ---Begin Header Middle Logo Area--- */
.hm-logo {
	padding: 20px 0;
	@include breakpoint (xx-small) {
		padding: 30px 0 20px;
	}
}

/* ---Begin Header Middle Menu Area--- */
/* ---Home One--- */
.hm-menu {
	> nav {
		> ul {
			> li {
				display: inline-block;
				padding-right: 30px;
				@include breakpoint (normal) {
					padding-right: 15px;
				}
				&:last-child {
					padding-right: 0;
				}
				&:hover {
					> a {
						color: $hover-color;
					}
				}
				// Begin Header Middle Dropdown Area
				@include dropdown;
				> ul {
					> li {
						position: relative;
						// Begin Header Middle Sub Dropdown Area
						> ul {
							&.hm-sub_dropdown {
								top: 30px;
								left: 100%;
								opacity: 0;
								visibility: hidden;
							}
						}
						> a {
							display: block;
							> i {
								position: absolute;
								top: 14px;
								right: 25px;
								display: block;
								font-size: 12px;
								transform: rotate(0);
								@include transition;
							}
						}
						&:hover {
							> .hm-sub_dropdown {
								top: 0;
								opacity: 1;
								visibility: visible;
							}
							> a {
								color: $hover-color;
								> i {
									transform: rotate(-90deg);
								}
							}
						}
						// Header Middle Sub Dropdown Area End Here
					}
				}
				// Header Middle Dropdown Area End Here
				// Begin Megamenu Area
				&.megamenu-holder {
					position: static;
					@include megamenu;
					> ul {
						> li {
							width: 25%;
							> span {
								&.megamenu-title {
									font-size: 18px;
									font-weight: 700;
									text-transform: uppercase;
									display: block;
									padding-bottom: 15px;
								}
							}
							> ul {
								> li {
									> a {
										display: block;
										line-height: 35px;
										font-size: 16px;
									}
								}
							}
						}
					}
				}
				// Megamenu Area End Here
				> a {
					display: block;
					padding: 40px 0;
					font-size: 15px;
					font-weight: bold;
					text-transform: uppercase;
					@include breakpoint (normal) {
						font-size: 14px;
					}
					> i {
						font-size: 14px;
						line-height: 1;
						padding-left: 5px;
					}
				}
			}
		}
	}
}

/* ---Home Two--- */
.header-bottom_area {
	> .container {
		position: relative;
	}
}
.hm-menu-2 {
	> nav {
		> ul {
			> li {
				> a {
					padding: 16px 0;
					color: $bg-white_color;
				}
			}
		}
	}
}

/* ---Begin Header Middle Minicart Area--- */
.hm-minicart_area {
	float: right;
	position: relative;
	> ul {
		> li {
			width: 114px;
			text-align: center;
			padding: 12px 0;
			background: #0084c5;
			display: inline-block;
			@include breakpoint (max-x_small) {
				width: 90px;
				padding: 15px 0;
			}
			> a {
				display: block;
				span {
					color: #ffffff;
					font-weight: bold;
					font-size: 16px;
					@include breakpoint (max-x_small) {
						font-size: 14px;
					}
				}
				.minicart-icon {
					font-size: 25px;
					color: #ffffff;
					@include breakpoint (max-x_small) {
						font-size: 20px;
					}
					.item-count {
						position: absolute;
						width: 25px;
						height: 25px;
						line-height: 28px;
						background: #333;
						border-radius: 50%;
						top: 5px;
						right: 15px;
						@include breakpoint (max-x_small) {
							right: 5px;
						}
					}
				}
			}
			// Begin Minicart Area
			.minicart-body {
				position: absolute;
				top: 100%;
				right: 0;
				width: 355px;
				background: $bg-white_color;
				border-top: 2px solid $hover-color;
				transform: scaleY(0);
				transform-origin: 0 0 0;
				@include transition;
				z-index: 9;
				@include breakpoint (max-medium) {
					display: none;
				}
				> li {
					float: left;
					width: 100%;
					padding: 0 20px;
					.minicart-single_item {
						padding: 30px 0 20px;
						border-bottom: 1px solid $border-color;
						max-height: 358px;
						overflow-y: auto;
						.minicart-img {
							position: relative;
							float: left;
							.product-quantity {
								position: absolute;
								width: 25px;
								height: 25px;
								line-height: 25px;
								background: #0084c5;
								border-radius: 100%;
								color: #fff;
								top: 0;
								left: 0;
							}
						}
						.minicart-content {
							overflow: hidden;
							padding-left: 15px;
							text-align: left;
							> .product-name {
								> h6 {
									margin-bottom: 0;
									padding-bottom: 10px;
									> a {
										display: block;
										white-space: nowrap;
										text-overflow: ellipsis;
										overflow: hidden;
									}
								}
							}
							.attributes_content {
								padding-top: 10px;
								line-height: 1;
							}
							.price-box {
								.new-price {
									color: #ea3a3c;
									font-size: 16px;
									font-weight: 700;
								}
							}
						}
					}
					.price_content {
						padding: 25px 0;
						.cart-subtotals {
							.subtotal-list {
								line-height: 25px;
								overflow: hidden;
								.label {
									float: left;
									font-weight: 600;
								}
								.value {
									float: right;
									font-weight: 600;
								}
							}
						}
						.minicart-button {
							padding-top: 20px;
							padding-bottom: 5px;
						}
					}
				}
			}
			&:hover {
				.minicart-body {
					transform: scaleY(1);
				}
			}
		}
	}
}

/* ---Begin Header Bottom Are--- */
.header-bottom_area {
	background-color: #333333;
	box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.15);
}

/* ---Begin Header Search Area | Home One--- */
.header-search_area {
	padding-top: 5px;
}
/* ---Begin Header Search Area | Home Two--- */
.header-search_area-2 {
	padding-top: 30px;
}
.header-search_box {
	display: flex;
	position: relative;
	background: #fff;
	border-radius: 5px;
	border: 1px solid #e1e1e1;
	min-width: 645px;
	height: 45px;
	float: left;
	@include breakpoint (normal) {
		min-width: 100%;
	}
	.jb-search_input {
		font-size: 13px;
		height: 45px;
		background: transparent;
		border: none;
		width: 100%;
		padding: 0 115px 0 22px;
	}
	.select-search-category {
		width: auto;
		line-height: 43px;
		height: 43px;
		margin: 0;
		border: 0;
		padding: 0 28px 0 25px;
		font-size: 13px;
		border-radius: 15px 0 0 15px;
		font-weight: 400;
		position: relative;
		left: auto;
		right: 90px;
		@include breakpoint (normal) {
			right: 50px;
		}
		&:before {
			right: 30px;
			top: 25px;
			color: #7e7e7e;
		}
		&:after {
			right: 0;
		}
		ul {
			&.list {
				height: 330px;
				overflow-y: auto;
				overscroll-behavior-x: none;
			}
		}
		.current {
			color: $input-placeholder_color;
			&:after {
				content: "";
				position: absolute;
				display: block;
				height: 44px;
				width: 1px;
				background: #e1e1e1;
				top: 0;
				left: 0;
				font-size: 13px;
			}
		}
	}
}

// Begin Header Contact Information Area
.contact-info {
	a {
		color: $bg-white_color;
		font-size: 16px;
		line-height: 30px;
		display: flex;
		justify-content: flex-end;
		padding-top: 15px;
		i {
			color: $hover-color;
			font-size: 24px;
			margin-right: 5px;
		}
	}
}
