/*----------------------------------------*/
/*  37 - Pages - Compare
/*----------------------------------------*/
.compare-area {
	padding: 60px 0;
}
.compare-table {
	.table {
		thead {
			th {
				border-width: 1px;
				padding: 15px 10px;
			}
		}
		tbody {
			tr {
				transition: all 0.3s ease-in-out;
				&:hover {
					background-color: rgba(0, 0, 0, .02);
				}
				td,
				th {
					vertical-align: middle;
					text-align: center;
				}
				th {
					min-width: 200px;
				}
				td {
					min-width: 250px;
					.compare-pdoduct-image {
						max-width: 200px;
						display: inline-block;
						padding: 20px 0;
						a {
							display: inline-block;
						}
						.jb-compare_btn {
							margin-top: 30px;
						}
					}
					.compare-product-name {
						font-size: 14px;
						font-weight: 700;
						margin-bottom: 0;
						a {
							-webkit-transition-property: color;
							-o-transition-property: color;
							transition-property: color;
							&:hover {
								color: #fed700;
							}
						}
					}
				}
			}
		}
	}
}
