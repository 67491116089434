/*----------------------------------------*/
/*  24 - Components - Modal
/*----------------------------------------*/
/* ---Modal--- */
.modal-wrapper {
	display: block !important;
	&.modal {
		visibility: hidden;
		opacity: 0;
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
		@include transition;
		z-index: -9;
	}
	&.show {
		visibility: visible;
		opacity: 1;
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
		z-index: 1050;
	}
	.modal-dialog {
		max-width: 880px;
		.modal-content {
			padding: 30px 30px 25px;
			.modal-body {
				padding: 0;
				.close {
					font-size: 30px;
					font-weight: 400;
					opacity: 1;
					-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
					position: absolute;
					right: 15px;
					top: 0;
					z-index: 99;
					@include transition;
					@include breakpoint (max-x_small) {
						top: 15px;
						right: 20px;
					}
					&:hover {
						color: $hover-color;
					}
				}
				.modal-inner-area {
					border-bottom: 1px solid $border-color;
					padding: 0 0 30px !important;
					.sp-content {
						padding-top: 0;
						@include breakpoint (max-x_small) {
							padding-top: 30px;
						}
						.short-desc {
							> p {
								margin-bottom: 0;
								padding-bottom: 25 !important;
							}
						}
						.quantity {
							padding-top: 0;
							.jb-quantity-btn_area {
								@include breakpoint (small) {
									display: block;
									padding: 30px 0 0;
								}
							}
							.jb-quantity-btn_area {
								@include breakpoint (xx-small) {
									display: block;
									padding: 30px 0 0;
								}
							}
						}
					}
				}
			}
			.jb-link_share {
				padding-top: 30px;
				> span {
					display: inline-block;
					padding-right: 15px;
					@include breakpoint (xx-small) {
						padding: 0 0 25px 0;
						text-transform: uppercase;
					}
				}
				> ul {
					display: inline-block;
					> li {
						> a {
							width: 40px;
							height: 40px;
							line-height: 43px;
							border-radius: 100%;
							background-color: transparent;
							text-align: center;
							color: #666;
							display: block;
							border: 1px solid #666;
							@include breakpoint (xx-small) {
								width: 35px;
								height: 35px;
								line-height: 38px;
							}
							&:hover {
								border-color: transparent;
							}
							> i {
								font-size: 16px;
							}
						}
						&:hover {
							&.facebook {
								> a {
									background-color: $facebook-color;
								}
							}
							&.twitter {
								> a {
									background-color: $twitter-color;
								}
							}
							&.youtube {
								> a {
									background-color: $youtube-color;
								}
							}
							&.google-plus {
								> a {
									background-color: $google-plus-color;
								}
							}
							&.instagram {
								> a {
									background-color: $instagram-color;
								}
							}
						}
					}
				}
				.wishlist-link_share {
					float: right;
					line-height: 45px;
					@include breakpoint (max-x_small) {
						float: unset;
						line-height: 1;
						padding-top: 30px;
					}
				}
			}
			.quantity {
				border-bottom: none !important;
			}
		}
	}
}

/*---Feedback Quick View Form---*/
.quickview-feedback_form {
	.modal-dialog {
		.modal-content {
			padding: 30px;
			.modal-body {
				.review-page-title {
					padding: 10px;
					font-size: 13px;
					color: $bg-white_color;
					text-transform: uppercase;
					background: $body-text_color;
				}
				.modal-inner-area {
					border: 0;
					padding-top: 23px !important;
					padding-bottom: 0 !important;
					.jb-review-product {
						img {
							border: 1px solid $border-color;
						}
						.jb-review-product-desc {
							line-height: 18px;
							.jb-product-name {
								padding: 23px 0 18px;
								margin-bottom: 0;
							}
						}
					}
					.jb-review-content {
						.feedback-area {
							.br-theme-fontawesome-stars {
								.br-widget {
									a {
										font-size: 14px;
										&.br-active {
											&:after {
												color: $body-text_color;
											}
										}
										&.br-selected {
											&:after {
												color: $body-text_color;
											}
										}
									}
								}
							}
							textarea,
							input {
								background: $bg-white_color;
								border: 1px solid $border-color;
								font-size: 14px;
								width: 100%;
							}
							.feedback {
								.feedback-title {
									margin-bottom: 0;
									padding-bottom: 15px;
								}
								form {
									p {
										margin-bottom: 0;
										padding-bottom: 20px;
										label {
											margin-bottom: 0;
											padding-bottom: 20px;
										}
										.required-fields {
											padding-top: 20px;
											display: block;
										}
										&.your-opinion {
											padding-bottom: 10px;
										}
									}
									.feedback-input {
										.feedback-form-author {
											margin-bottom: 0;
											input {
												width: 100%;
											}
										}
									}
									.feedback-inner_btn {
										position: relative;
										a {
											background: $body-text_color;
											color: $bg-white_color !important;
											width: 80px;
											font-size: 14px !important;
											height: 30px;
											line-height: 30px;
											text-align: center;
											left: 110px;
											right: auto;
											top: 0;
											display: block;
											transition: all 0.3s ease-in-out;
											&:hover {
												background: $hover-color;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
