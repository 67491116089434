/*----------------------------------------*/
/*  19 - Components - Navigation
/*----------------------------------------*/
/* ---Offcanvas Menu Wrapper--- */
.offcanvas-menu-wrapper {
	.offcanvas-menu-inner {
		position: fixed;
		top: 0;
		right: -285px;
		width: 285px;
		height: 100%;
		background: $bg-white_color;
		z-index: 9999;
		visibility: hidden;
		@include transition;
		@include breakpoint (max-x_small) {
			width: 270px;
		}
	}
	&.open {
		.offcanvas-menu-inner {
			left: auto;
			right: 0;
			visibility: visible;
			padding: 105px 0 0;
		}
	}
}
/* ---Offcanvas Navigation Area--- */
.offcanvas-navigation {
	.offcanvas-menu {
		overflow-y: auto;
		min-height: 165px;
		& > li {
			height: 100%;
			& > a {
				span {
					position: relative;
					font-weight: 600;
				}
			}
		}
		li {
			position: relative;
			& > .menu-expand {
				position: absolute;
				right: 0;
				top: 2px;
				width: 50px;
				height: 100%;
				line-height: 40px;
				cursor: pointer;
				text-align: center;
				margin-right: 4px;
				@include transition;
			}
			a {
				font-size: 14px;
				font-weight: 400;
				text-transform: uppercase;
				display: block;
				padding: 10px 20px;
			}
			.sub-menu {
				padding-left: 10px;
				li {
					a {
						text-transform: capitalize;
						font-size: 13px;
					}
				}
			}
			&.menu-open {
				& > .menu-expand {
					i {
						&:before {
							content: '\f068';
						}
					}
				}
			}
			&:hover {
				& > a {
					color: $hover-color !important;
				}
				& > .menu-expand {
					color: $hover-color !important;
				}
			}
		}
	}
}

/* ---Offcanvas Menu button--- */
.menu-btn {
	display: none;
	position: absolute;
	top: 33px;
	right: 160px;
	font-size: 32px;
	line-height: 1;
	@include breakpoint (max-medium) {
		display: block;
		top: 35px;
		right: 149px;
	}
	@include breakpoint (max-x_small) {
		top: 35px;
		right: 124px;
		font-size: 25px;
	}
}

/* ---Offcanvas Close Button--- */
.btn-close {
	position: absolute;
	top: 0;
	left: -60px;
	background: #333;
	width: 60px;
	height: 60px;
	line-height: 63px;
	text-align: center;
	color: $bg-white_color;
	z-index: 10;
	font-size: 24px;
	@include transition;
	@include breakpoint (xx-small) {
		left: -50px;
		width: 50px;
	}
	&:hover {
		background-color: $hover-color;
		color: $bg-white_color !important;
		> i {
			transform: rotate(90deg);
		}
	}
	> i {
		transform: rotate(0);
		@include transition;
	}
}

/* ---Offcanvas Search Area--- */
.offcanvas-search {
	position: absolute;
	z-index: 9;
	top: 0;
	left: 0;
	width: calc(100% - 0px);
	padding: 10px;
	background-color: $border-color;
	.hm-searchbox {
		position: relative;
		> input {
			background-color: $border-color;
			border: 0;
			height: 40px;
			line-height: 40px;
			width: 100%;
			padding: 0 52px 0 15px;
		}
		> .search_btn {
			position: absolute;
			top: 13px;
			right: 18px;
			border: 0;
			background: transparent;
			color: $body-text_color;
		}
	}
}

/* ---Header Top Offcanvas Navigation--- */
.ht-offcanvas_navigation {
	padding-top: 25px;
	> ul {
		> li {
			> a {
				> span {
					text-transform: capitalize;
				}
			}
		}
	}
}

/* ---Category Menu--- */
.category-menu {
	position: relative;
	@include breakpoint (max-medium) {
		padding-top: 30px;
	}
	@include breakpoint (max-x_small) {
		padding-bottom: 30px;
	}
	@include breakpoint (small) {
		padding-bottom: 30px;
	}
	.category-heading {
		position: relative;
		cursor: pointer;
		background: $hover-color;
		padding: 19px 0;
		> h2 {
			margin: 0;
			color: $bg-white_color;
			padding: 0;
			text-transform: uppercase;
			font-size: 16px;
			font-weight: 500;
			&:before {
				font-size: 14px;
				content: '\f078';
				font-family: 'Font Awesome 5 Free';
				font-weight: 600;
				color: $bg-white_color;
				display: block;
				position: absolute;
				right: 30px;
				top: 19px;
				@include breakpoint (normal) {
					right: 15px;
				}
			}
			&:after {
				content: "\f03c";
				font-family: 'Font Awesome 5 Free';
				font-weight: 600;
				position: absolute;
				top: 18px;
				left: 20px;
			}
			> span {
				padding-left: 50px;
				color: $bg-white_color;
				font-size: 14px;
				font-weight: 600;
				@include breakpoint (normal) {
					padding-left: 40px;
				}
			}
		}
	}
}

.category-menu-list {
	background: $bg-white_color;
	position: absolute;
	left: 0;
	top: calc(100% - 1px);
	display: block;
	width: 100%;
	z-index: 3;
}

.category-menu-list > ul > li {
	position: relative;
}

.header-bottom-area.header-style-3 .category-menu-list > ul > li:first-child {
	border-top: 1px solid #ebebeb;
}

.category-menu-list > ul > li > a {
	display: block;
	text-transform: capitalize;
	overflow: hidden;
	font-size: 14px;
	font-weight: 400;
	padding: 15px 20px 15px 20px;
	border-bottom: 1px solid rgba(239, 239, 239, 0.2);
}

.category-menu-list > ul > li:hover > a {
	color: #0083c1;
}

.category-menu-list > ul > li {
	@include transition;
	border-bottom: 1px solid $border-color;
	&:last-child > a:before {
		display: none;
	}
	&.right-menu {
		> a::after {
			content: '\f078';
			font-family: 'Font Awesome 5 Free';
			font-weight: 600;
			@include transition;
			font-size: 12px;
			position: absolute;
			top: 15px;
			right: 30px;
		}
		&:hover > a::after {
			transform: rotate(-90deg);
		}
	}
}

.category-menu-list > ul > li.rx-parent:hover > a {
	color: #ffffff !important;
}

.cat-mega-menu {
	position: absolute;
	top: 0;
	left: 100%;
	background: #ffffff;
	z-index: 999;
	width: 220px;
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
	opacity: 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	visibility: hidden;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	width: 640px;
	padding: 30px;
	overflow: hidden;
	border-top: 2px solid $hover-color;
}

.cat-mega-menu-2 {
	width: 840px;
}
.cat-mega-menu-3 {
	width: 440px;
}

li.right-menu {
	.cat-mega-menu > li {
		width: 33.333%;
		float: left;
	}
	.cat-mega-menu-2 > li {
		width: 25%;
	}
	.cat-mega-menu-3 > li {
		width: 50%;
	}
}

.cat-mega-menu > li {
	&.cat-mega-title {
		> a {
			font-weight: 600;
			text-transform: uppercase;
			font-size: 16px;
			margin-bottom: 15px;
			display: block;
			line-height: 20px;
			position: relative;
		}
		a:hover {
			color: #ffffff;
		}
	}
	> ul > li > a {
		font-size: 14px;
		display: block;
		line-height: 30px;
		font-weight: 400;
		color: #666;
		text-transform: capitalize;
		&:hover {
			color: #0083c1;
		}
	}
}

.category-menu-list > ul > li:hover {
	.cat-dropdown,
	.cat-mega-menu {
		opacity: 1;
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
		visibility: visible;
	}
}

/* ---Category Menu In Mobile Device--- */
@include breakpoint (max-medium) {
	.category-menu-list > ul > li.right-menu > a:after {
		display: none;
	}
	.category-menu {
		width: 100%;
		position: relative;
	}
	.cat-mega-menu,
	.cat-dropdown {
		position: static;
		z-index: 9;
		opacity: 1;
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
		visibility: visible;
		background-color: transparent;
		padding: 0;
		box-shadow: none;
		border: 0;
		width: 100%;
		-webkit-transition: none;
		transition: none;
	}
	.category-menu-list > ul > li i {
		position: absolute;
		right: 0;
		top: 0;
		cursor: pointer;
		height: 50px;
		width: 70px;
		line-height: 50px;
		z-index: 9;
		display: block;
	}
	.category-menu-list > ul > li i:before {
		background-color: #606060;
		width: 8px;
		height: 2px;
		content: "";
		position: absolute;
		left: 50%;
		margin-left: -3px;
		margin-top: -1px;
		top: 50%;
	}
	.category-menu-list > ul > li i:after {
		background-color: #606060;
		width: 2px;
		height: 8px;
		content: "";
		position: absolute;
		left: 50%;
		margin-top: -4px;
		margin-left: 0;
		top: 50%;
		transition: all 0.3s ease 0s;
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
	}
	.category-menu-list > ul > li i.menu-expand.active:after {
		background-color: transparent;
	}
	.category-menu-list > ul > li i.expand:after {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}
	li.right-menu .cat-mega-menu > li {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		width: 100%;
		padding: 0;
		position: relative;
	}
	.cat-mega-menu > li.cat-mega-title > a:after {
		display: none;
	}
	.cat-mega-menu > li.cat-mega-title > a,
	.cat-mega-menu > li > ul > li > a,
	.cat-dropdown > li > a {
		padding: 15px 20px 15px 20px;
		font-size: 13px;
		color: #444444;
		font-weight: normal;
		position: relative;
		margin: 0;
		display: block;
		text-transform: inherit;
	}
	.cat-mega-menu > li.cat-mega-title:last-child > a,
	.cat-mega-menu > li > ul > li:last-child > a,
	.cat-dropdown > li:last-child > a {
		border-bottom: 0;
	}
}
