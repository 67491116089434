/*----------------------------------------*/
/*  04 - Utilities - Color
/*----------------------------------------*/

/* ---Backgroud Color | Only For HTML Markup---*/
.bg--white_smoke {
	background-color: $white-smoke_color;
}
.bg--white {
	background-color: $bg-white_color;
}

.bg--night_rider {
	background-color: $body-text_color;
}

.bg--silver {
	background-color: $bg-silver_color;
}
.bg--nero {
	background-color: $bg-nero_color;
}
